import axios, { AxiosInstance } from 'axios'

import config from './config'
import { simplifyHeaders } from './simple-request'

export interface SeBarRequest extends AxiosInstance {
  setRequestBaseUrl(baseUrl: string): this
}

export interface SeBarApiMetadata {
  _trace_id?: string
  _pagination?: any
}

export type SeBarApiResponse<TResponse> = TResponse & SeBarApiMetadata

const unpackPayload = (data): SeBarApiResponse<any> => {
  const result: SeBarApiResponse<any> = data && data.result
  if (!(result && result instanceof Object)) return data
  const meta = data.metadata || {}
  if (result && meta.pagination) {
    result._pagination = meta.pagination
  }
  if (meta.trace_id) {
    result._trace_id = meta.trace_id
  }
  return result
}

const request = axios.create({
  withCredentials: true
}) as SeBarRequest

request.defaults.headers = {
  Accept: 'application/vnd.ngin-api.v2, application/json',
  'X-SPORTS-VERSION': 'v1',
}

request.defaults.baseURL = config.urls.seApi

const responseSuccessInterceptor = (response) => unpackPayload(response.data)

const responseErrorInterceptor = (error) => {
  if (error.response?.data) {
    error.data = error.response.data.error
  }
  return Promise.reject(error)
}

const requestInterceptor = (config) => {
  if (config?.simpleHeaders) {
    config.headers = simplifyHeaders(config.method, config.headers)
  }
  return config
}

request.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor)
request.interceptors.request.use(requestInterceptor)

request.setRequestBaseUrl = function setRequestBaseUrl(baseUrl: string): SeBarRequest {
  request.defaults.baseURL = baseUrl
  return this
}

export { request }
export default request
