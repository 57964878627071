import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

import request from '../lib/request'
import Service from '../lib/service'
import config from '../lib/config'

class FirebaseService extends Service {
  constructor() {
    super()
  }

  getJwt() {
    return this._getJwt = this._getJwt = request
      .post('firebase/auth', {}, { baseURL: this.derivedBaseUrl })
      .then(resp => resp.jwt)
  }

  getData(appName, path) {
    return this.getCached('ref', `${appName}/${path}`, () => {
      return this.getDatabase(appName).then(database => database.ref(path))
    })
  }

  getApp(appName) {
    return this.getCached('app', appName, () => {
      const app = this.initApp(appName)
      const auth = firebase.auth(app)
      return this.getJwt()
        .then(jwt => auth.signInWithCustomToken(jwt))
        .then(() => app)
    })
  }

  getDatabase(appName) {
    return this.getCached('database', appName, () => {
      return this.getApp(appName).then(app => firebase.database(app))
    })
  }

  getCached(type, key, fn) {
    const data = this.data[type] = this.data[type] || {}
    return data[key] = data[key] || fn()
  }

  initApp(appName) {
    const apps = this.data.apps = this.data.apps || {}
    return apps[appName] = apps[appName] || firebase.initializeApp(config.firebase[appName], appName)
  }
}

const firebaseService = new FirebaseService()

export default firebaseService
